<template>
    <SectionBanner :title="categoryDetail?.title" :des="categoryDetail?.description" />
    <SectionListFilter :filter="filter" :keyFilter="'categories'" :urlModel="categoryDetail?.url" />
    <SectionRelatedProduct :data="trendingProducts" />
</template>

<script setup lang="ts">
import SectionBanner from './SectionBanner.vue'
import SectionListFilter from '../collection/[slug]/SectionListFilter.vue'
import SectionRelatedProduct from './SectionRelatedProduct.vue'
definePageMeta({
    layout: 'custom'
})
const globalSettings = inject('globalSettings')
const slug = useRoute().params.slug
const brand = useRoute().query.brand
const { getProductFilterData, getProductCategories, getTrendingProducts, searchProducts } = useProducts(globalSettings)
const categoryDetail = await getProductCategories({ slug: slug })
// console.log('🚀 ~ file: [slug].vue:16 ~ categoryDetail:', categoryDetail)
const filter = await getProductFilterData({ category: { slug: slug } })
const trendingProducts = await getTrendingProducts({ query: { limit: 3 } })
useDirectusCollectionSeo('product_categories', {
    ...categoryDetail,
    seo_detail: {
        ...categoryDetail?.seo_detail
    }
})
</script>

<style></style>
