<template>
    <section class="bg-[#F8F8F8] mt-24">
        <div class="container pt-24 pb-32">
            <v-content option_key="category_related_sub_title" class="text-blacks-50 text-lg"> </v-content>
            <v-content
                option_key="category_related_title"
                class="text-blacks-100 text-3xl lg:text-40px xl:text-48px font-medium pt-3"
            >
            </v-content>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 xl:mt-12">
                <nuxt-link :to="item?.url" v-for="item in data" class="flex flex-col gap-6 group">
                    <div class="overflow-hidden">
                        <div class="aspect-1/1 max-h-sm overflow-hidden b-0.5 b-gray-200">
                            <NuxtImg
                                :src="
                                    getThumbnail(
                                        item?.images && item?.images.length > 0 ? item?.images[0] : '',
                                        800,
                                        true
                                    )
                                "
                                width="800"
                                sizes="sm:100vw md:50vw lg:800px "
                                loading="lazy"
                                alt="Banner Home"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover duration-400 transform group-hover:scale-110"
                            />
                        </div>
                    </div>
                    <div>
                        <p class="text-blacks-100 text-lg font-medium hover:underline duration-300">
                            {{ item?.title }}
                        </p>
                        <p class="text-base text-blacks-50 mt-1 md:mt-2 line-clamp-3">
                            {{ regexText(item?.description) }}
                        </p>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
defineProps({
    data: {
        type: Array as any
    }
})
const regexText = (text: string) => {
    return text ? text.replace(/-/g, ' ') : ''
}
</script>

<style></style>
