<template>
    <div class="relative flex flex-col items-center justify-center">
        <div class="container w-full h-full py-20 lg:py-27">
            <div class="w-full">
                <div data-aos="fade-right" data-aos-delay="300" class="text-blacks-100 text-sm mb-4">
                    <GlobalBreadcrumb :breadcrumb="breadcrumb" isLight />
                </div>
                <p data-aos="fade-right" data-aos-delay="300" class="text-48px text-blacks-100 font-medium !max-w-194">
                    {{ title }}
                </p>
                <div
                    v-html="convertedText"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    class="text-base font-medium text-blacks-70 mt-3"
                ></div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
const props = defineProps({
    title: {
        type: String
    },
    des: {
        type: String
    }
})
const textWithNewlines = props.des
const convertedText = convertNewlinesToBr(textWithNewlines)
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Sản phẩm' + ' ' + props.title
    }
    // {
    //     title: !modelCategory.category.parent ? '' : modelCategory.category?.title,
    //     url: renderData.model?.url
    // }
])
</script>
